// @ts-check
const { NODE_ENV, REACT_APP_CLIENT_NAME, REACT_APP_FALSE_COUNT, REACT_APP_REGISTRATION_DETECTION_INTERVAL, REACT_APP_DETECTION_INTERVAL } = process.env

// /**
//  * @type {{NODE_ENV:string, REACT_APP_CLIENT_NAME:string, REACT_APP_FALSE_COUNT:string}}
//  */
const env = {
    NODE_ENV,
    REACT_APP_CLIENT_NAME,
    REACT_APP_FALSE_COUNT,
    REACT_APP_REGISTRATION_DETECTION_INTERVAL,
    REACT_APP_DETECTION_INTERVAL
}

module.exports = { env }