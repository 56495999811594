import React, {
    useEffect,
    useState,
    Suspense,
    useCallback,
    useMemo,
    forwardRef,
} from "react";
import userStyles from "../../../styles/UI/layout/FooterStyles";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import aimesoftLogo from "../../../images/aimesoft_logo.png";
// import stop_icon from '../../../images/Stop_button.png'
import {
    resetChat,
    setOnScreenKeyboard,
    stopAllActions,
} from "../../../store/actions/chatActions";
import { Link } from "react-router-dom";
import Button from "../../Customized Libraries/CustomButtons/Button";
import back from "../../../images/back.png";
import home from "../../../images/home.png";
import faceRegistrationLogo from "../../../images/face_registration2.png";
import settingsIcon from "../../../images/settings.svg";
import openDoorIcon from "../../../images/door_open.png";
import clockIcon from "../../../images/clock.png";
import stop from "../../../images/stop.png";
import resetIcon from "../../../images/reset.svg";
import dashboardIcon from "../../../images/dashboard.svg";
import { canDoorOpen, openDoor } from "../../../store/actions/doorActions";
import { addToNotifications } from "../../../store/actions/notificationActions";
import FaceRegistrationModal from "../modals/FaceRegistrationModal";
import Settings from "../settings/Settings";
import { translate } from "../../../utils/i18n";
import PasswordModal from "../modals/PasswordModal";
import { SuspenseImg as SuspenseImgClone } from "../SuspenseImg";
import {
    setHasBowed,
    setIsUserFalseCount,
} from "../../../store/actions/faceCamActions";
import {
    clearUser,
    resetRecognizedCount,
} from "../../../store/actions/userActions";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import { useApplicationSettings } from "../../../hooks/settings/useApplicationSettings";
import { checkOutUser } from "../../../store/actions/checkInCheckOutActions";

// import check_out from "../images/c"

// const SuspenseImg = forwardRef(function SuspenseImg(props, ref){
//     return <SuspenseImgClone childRef={ref} {...props}/>
// })

const SuspenseImg = forwardRef(function SuspenseImg(props, ref) {
    return (
        <Suspense fallback={<Spin />}>
            <SuspenseImgClone childRef={ref} {...props} />
        </Suspense>
    );
});
const propIsValid = (prop) => (typeof prop != "undefined" ? prop : false);

const Footer = ({ history, location }) => {
    // return null
    const classes = userStyles();
    const dispatch = useDispatch();
    const { faceRecognition = {}, camera = {} } = useApplicationSettings([
        "faceRecognition",
        "camera",
    ]);
    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );
    const isUserRecognized = useSelector(
        (state) => state.user.isUserRecognized
    );
    const isPerformingAction = useSelector(
        (state) => state.chat.isPerformingAction
    );
    const userInfo = useSelector((state) => state.user.userInfo);
    const language = useSelector((state) => state.language.currentLanguage);
    const state = location.state;
    const [isPasswordDisabled, setIsPasswordDisabled] = useState(false);
    const [stopIconOpen, setStopIconOpen] = useState(true);

    const [isRegistrationModalOpen, setIsRegistrationModalOpen] =
        useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [isAdminValidationModalOpen, setIsAdminValidationModalOpen] =
        useState(false);
    // const isOnScreenKeyboard = useSelector(state => state.chat.isOnScreenKeyboard)
    const FALSE_COUNT = parseInt(process.env.REACT_APP_FALSE_COUNT);

    useEffect(() => {
        if (
            state !== undefined &&
            state.showRegistrationModal &&
            location.pathname === "/"
        ) {
            setIsPasswordDisabled(true);
            setIsRegistrationModalOpen(true);
            history.replace();
        } else setIsPasswordDisabled(false);
    }, [state, history.location.pathname]);

    useEffect(() => {
        const timer = setTimeout(() => setStopIconOpen(false), 1000);
        return () => clearTimeout(timer);
    }, [stopIconOpen]);

    const handleDoorOpen = useCallback(() => {
        if (isUserRecognized && userInfo?.role === "staff" && canDoorOpen())
            dispatch(openDoor(language));
        else
            dispatch(
                addToNotifications({
                    message: translate("You are not authorized!"),
                    type: "ERROR",
                    size: "sm",
                })
            );
    }, [isUserRecognized, userInfo, language, dispatch]);

    const handleFaceRegistration = useCallback(() => {
        setIsPasswordDisabled(false);
        setIsRegistrationModalOpen(true);
    }, []);

    const isRegistrationDisabled = useMemo(
        () => !propIsValid(camera.on) || !propIsValid(faceRecognition.on),
        [camera, faceRecognition, updateSettings]
    );

    const resetApp = useCallback(() => {
        dispatch(setHasBowed(false));
        dispatch(setIsUserFalseCount(FALSE_COUNT, false));
        dispatch(resetRecognizedCount());
        dispatch(resetChat());
        dispatch(clearUser());
        history.location.pathname !== "/" && history.push("/");
    }, [history, dispatch, FALSE_COUNT]);

    const handleAdminRedirect = useCallback(
        () => setIsAdminValidationModalOpen(true),
        []
    );
    const handleAdminValidationModalClose = useCallback(
        () => setIsAdminValidationModalOpen(false),
        []
    );
    const handleSettingClose = useCallback(
        () => setIsSettingsModalOpen(false),
        []
    );
    const handleFaceRegistrationModalClose = useCallback(
        () => setIsRegistrationModalOpen(false),
        []
    );
    const handleCheckout = useCallback(
        () => dispatch(checkOutUser(userInfo._id)),
        [userInfo]
    );
    const handleSettingModalOpen = useCallback(
        () => setIsSettingsModalOpen(true),
        []
    );
    const handleInterruptClick = useCallback(
        () => dispatch(stopAllActions()),
        [dispatch]
    );
    const handleInterruptMouseOver = useCallback(
        () => setStopIconOpen((val) => !val),
        []
    );
    const handleHomeClick = useCallback(() => history.push("/"), []);

    const faceRegistrationModal = useMemo(
        () => (
            <FaceRegistrationModal
                open={isRegistrationModalOpen}
                handleClose={handleFaceRegistrationModalClose}
                history={history}
            />
        ),
        [isRegistrationModalOpen, handleFaceRegistrationModalClose, history]
    );

    const settings = useMemo(
        () => (
            <Settings
                open={isSettingsModalOpen}
                handleClose={handleSettingClose}
            />
        ),
        [isSettingsModalOpen, handleSettingClose]
    );

    const checkoutSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Checkout")}
                arrow={true}
                placement={"top"}
            >
                <SuspenseImg
                    src={clockIcon}
                    alt={"checkout"}
                    className={classes.icon}
                />
            </Tooltip>
        ),
        [classes.icon, language]
    );

    const adminSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Admin Page")}
                arrow={true}
                placement={"top"}
            >
                <SuspenseImg
                    src={dashboardIcon}
                    alt={"Admin Page"}
                    className={classes.icon}
                />
            </Tooltip>
        ),
        [classes.icon, language]
    );

    const doorOpenSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Open Door")}
                arrow={true}
                placement={"top"}
            >
                <SuspenseImg
                    src={openDoorIcon}
                    alt="Open Door"
                    className={classes.icon}
                />
            </Tooltip>
        ),
        [classes.icon, language]
    );

    const faceRegistrationSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Face Registration")}
                arrow={true}
                placement={"top"}
            >
                <SuspenseImg
                    src={faceRegistrationLogo}
                    alt="Face registration"
                    className={classes.icon}
                />
            </Tooltip>
        ),
        [classes.icon, language]
    );

    const settingsSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Settings")}
                arrow={true}
                placement={"top"}
            >
                <SuspenseImg
                    src={settingsIcon}
                    alt="settings"
                    className={classes.icon}
                />
            </Tooltip>
        ),
        [classes.icon, language]
    );

    const backSuspenseImg = useMemo(
        () => (
            <Tooltip title={translate("Back")} arrow={true} placement={"top"}>
                <SuspenseImg src={back} alt="Back" className={classes.icon} />
            </Tooltip>
        ),
        [classes.icon, language]
    );

    const resetSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Reset App")}
                arrow={true}
                placement={"top"}
            >
                <SuspenseImg
                    src={resetIcon}
                    alt="reset app"
                    className={classes.icon}
                />
            </Tooltip>
        ),
        [classes.icon, language]
    );

    const stopSuspenseImg = useMemo(
        () => (
            <Tooltip
                title={translate("Click Here To Interrupt")}
                arrow={true}
                placement={"top"}
                open={stopIconOpen}
            >
                <SuspenseImg
                    src={stop}
                    alt={"stop Icon"}
                    className={classes.stopIcon}
                />
            </Tooltip>
        ),
        [classes.stopIcon, language]
    );

    const homeSuspenseImg = useMemo(
        () => (
            <Tooltip title={translate("Home")} arrow={true} placement={"top"}>
                <SuspenseImg src={home} alt="home" className={classes.icon} />
            </Tooltip>
        ),
        [classes.icon, language]
    );

    // const toggleKeyboard = ()=>{
    //     dispatch(setOnScreenKeyboard(!isOnScreenKeyboard))
    // }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {location.pathname !== "/" && (
                    <Button
                        color={"primaryButtonTheme"}
                        textColor={"buttonTextTheme"}
                        size={"lg"}
                        round
                        className={classes.button}
                        onClick={history.goBack}
                    >
                        {backSuspenseImg}
                    </Button>
                )}
                <Button
                    color={"primaryButtonTheme"}
                    textColor={"buttonTextTheme"}
                    size={"lg"}
                    round
                    className={classes.button}
                    onClick={handleCheckout}
                >
                    {checkoutSuspenseImg}
                </Button>
                <Button
                    color={"primaryButtonTheme"}
                    textColor={"buttonTextTheme"}
                    size={"lg"}
                    round
                    className={classes.button}
                    onClick={handleDoorOpen}
                >
                    {doorOpenSuspenseImg}
                </Button>

                {!isRegistrationDisabled && (
                    <Button
                        color={"primaryButtonTheme"}
                        textColor={"buttonTextTheme"}
                        size={"lg"}
                        round
                        className={classes.button}
                        onClick={handleFaceRegistration}
                    >
                        {faceRegistrationSuspenseImg}
                    </Button>
                )}
                <Button
                    color={"primaryButtonTheme"}
                    textColor={"buttonTextTheme"}
                    size={"lg"}
                    round
                    className={classes.button}
                    onClick={handleSettingModalOpen}
                >
                    {settingsSuspenseImg}
                </Button>

                <Button
                    color={"primaryButtonTheme"}
                    textColor={"buttonTextTheme"}
                    size={"lg"}
                    round
                    className={classes.button}
                    onClick={handleAdminRedirect}
                >
                    {adminSuspenseImg}
                </Button>
                <Button
                    color={"primaryButtonTheme"}
                    textColor={"buttonTextTheme"}
                    size={"lg"}
                    round
                    className={classes.button}
                    onClick={resetApp}
                >
                    {resetSuspenseImg}
                </Button>
                {/*<Button color="aime" size={"lg"} round className={classes.button} onClick={toggleKeyboard}>*/}
                {/*    <Tooltip title={"Face registration"} arrow  placement={"top"} open={registrationIconOpen} onClose={()=>setRegistrationIconOpen(false)} onOpen={()=>setRegistrationIconOpen(true)}>*/}
                {/*        <img src={face_registration_logo} alt="Face registration" className={classes.icon}/>*/}
                {/*    </Tooltip>*/}
                {/*</Button>*/}
                {isPerformingAction && (
                    <Button
                        color={"primaryButtonTheme"}
                        textColor={"buttonTextTheme"}
                        size={"lg"}
                        round
                        className={classes.button}
                        onClick={handleInterruptClick}
                        onMouseOver={handleInterruptMouseOver}
                    >
                        {stopSuspenseImg}
                    </Button>
                )}
                {history.location.pathname !== "/" && (
                    <Button
                        color={"primaryButtonTheme"}
                        textColor={"buttonTextTheme"}
                        size={"lg"}
                        round
                        className={classes.button}
                        onClick={handleHomeClick}
                    >
                        {homeSuspenseImg}
                    </Button>
                )}
            </div>
            <div className={classes.footerTitle}>
                <div>
                    {translate("AimeReception - Virtual Receptionist Software")}
                </div>
                <div>{translate("Powered by Aimesoft JSC")}</div>
            </div>
            <Link to={"/"} className={classes.removeDefaultLink}>
                <Suspense fallback={<Spin />}>
                    <SuspenseImgClone
                        src={aimesoftLogo}
                        alt={"aimesoft logo"}
                        className={classes.logo}
                    />
                </Suspense>
            </Link>
            <PasswordModal
                open={isSettingsModalOpen}
                handleClose={handleSettingClose}
                history={history}
            >
                {settings}
            </PasswordModal>
            <PasswordModal
                open={isRegistrationModalOpen}
                handleClose={handleFaceRegistrationModalClose}
                disabled={isPasswordDisabled}
                history={history}
            >
                {faceRegistrationModal}
            </PasswordModal>
            <PasswordModal
                open={isAdminValidationModalOpen}
                handleClose={handleAdminValidationModalClose}
                history={history}
                redirectTo={"/admin"}
            />
        </div>
    );
};

Footer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default Footer;
