import axios from "axios";
import {
    ADD_TO_CHAT,
    SET_SESSION,
    ADD_NOTIFICATION,
    SET_RECORDING,
    SET_IS_THINKING,
    REMOVE_LAST_ITEM_FROM_CHAT,
    RESET_CHAT,
    SET_IS_GAPI_READY,
    STOP_ALL_AUDIO,
    ADD_AUDIO,
    SET_IS_PERFORMING_ACTION,
    SET_IS_ON_SCREEN_KEYBOARD,
    SET_BOT_ID,
} from "./types";
import {
    DEFAULT_SLACK_CONFIG,
    DEFAULT_TEAMS_CONFIG,
    defaultOnFindMapFailResponse,
    defaultVoiceResponses,
    languageCodes,
    languageCodes_,
    MESSAGE_PROVIDER_FIELD_NAMES,
    TTSVoices,
} from "../../styles/constants";
import { setReceptionist } from "./receptionistActions";
import SpeechRecognition from "react-speech-recognition";
import Tokenizer_jp from "wink-tokenizer";
import { translate } from "../../utils/i18n";
import {
    addAudioToLocalStorage,
    getAudioFromTextInLocalStorage,
} from "./localStorageActions";
import store from "../../store";
import { bots } from "../../config.json";
import { sendMessageWithProvider } from "./teamsActions";

const Tokenizer = require("sentence-tokenizer");
const tokenizer = new Tokenizer("Chuck");
const tokenizer_jp = Tokenizer_jp();

let speakTimeout = null;
let isUsingTTSApi = false;
let calenderAPIToken = null;

// send Message
export const sendMessage =
    (question, session_id, modals, history, language, isGapiReady) =>
    async (dispatch) => {
        const bot_token = bots.find(
            (bot) => bot.bot_id === getCurrentBotId()
        )?.bot_token;
        const api_url =
            store.getState()?.settings?.applicationSettings?.chatAPI?.server ||
            `${process.env.REACT_APP_API.split("/")
                .slice(0, -1)
                .join("/")}/${getCurrentBotId()}` ||
            process.env.REACT_APP_API;
        const api_token =
            store.getState()?.settings?.applicationSettings?.chatAPI?.token ||
            bot_token ||
            process.env.REACT_APP_API_TOKEN;
        const url = `${api_url}/chat_get_response_v2?q=${question}&session=${session_id}&client_token=${api_token}&lang=ja`;
        dispatch({
            type: ADD_TO_CHAT,
            message: question,
            isReceiver: false,
        });
        dispatch({
            type: SET_IS_THINKING,
            isThinking: true,
        });

        console.log(`sending message`);
        await axios
            .get(url)
            .then((res) => {
                console.log(res);
                if (!res.data.error) {
                    const message = getMessageResponse(
                        res.data.answer,
                        language
                    );
                    if (message.length > 0) {
                        dispatch({
                            type: ADD_TO_CHAT,
                            message,
                            isReceiver: true,
                        });
                    }

                    // handle server responses/actions
                    dispatch(
                        handleMessageResponse(
                            res.data.answer,
                            modals,
                            history,
                            language,
                            isGapiReady
                        )
                    );

                    // set session
                    dispatch({
                        type: SET_SESSION,
                        session: res.data.session_id,
                    });
                } else {
                    let err = Object.values(JSON.parse(res.data.errmsg)).flat(
                        Infinity
                    );
                    if ("The q field is required." === err?.[err?.length - 1]) {
                        err = ["You didn't say anything!"];
                    }
                    err?.[err?.length - 1] &&
                        dispatch({
                            type: ADD_NOTIFICATION,
                            payload: {
                                type: "ERROR",
                                message: err[err.length - 1],
                                size: "sm",
                            },
                        });
                    dispatch({
                        type: REMOVE_LAST_ITEM_FROM_CHAT,
                    });
                }
            })
            .catch((err) => {
                console.log("Error");
                console.log(err);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                dispatch({
                    type: REMOVE_LAST_ITEM_FROM_CHAT,
                });
            });
        dispatch({
            type: SET_IS_THINKING,
            isThinking: false,
        });
    };

// send Message with bot id
export const sendMessageWithBotID = (() => {
    let sessionId = -1;
    return (
            question,
            display,
            apiBot,
            apiToken,
            modals,
            history,
            language,
            isGapiReady
        ) =>
        async (dispatch) => {
            const kbot_api =
                store.getState()?.settings?.applicationSettings?.chatAPI
                    ?.kbotEndpoint || process.env.REACT_APP_KBOT_API;
            const url = `${kbot_api}/${apiBot}/chat_get_response_v2?q=${question}&session=${sessionId}&client_token=${apiToken}&lang=ja`;
            dispatch({
                type: ADD_TO_CHAT,
                message: display,
                isReceiver: false,
            });
            dispatch({
                type: SET_IS_THINKING,
                isThinking: true,
            });

            console.log(`sending message`);
            await axios
                .get(url)
                .then((res) => {
                    // console.log(res);
                    if (!res.data.error) {
                        const message = getMessageResponse(
                            res.data.answer,
                            language
                        );
                        if (message.length > 0) {
                            //this call test dispatch. to dispatch to our reducer
                            dispatch({
                                type: ADD_TO_CHAT,
                                message,
                                isReceiver: true,
                            });
                        }

                        // handle server responses/actions
                        dispatch(
                            handleMessageResponse(
                                res.data.answer,
                                modals,
                                history,
                                language,
                                isGapiReady
                            )
                        );
                    } else {
                        let err = Object.values(
                            JSON.parse(res.data.errmsg)
                        ).flat(Infinity);
                        if (
                            "The q field is required." === err[err.length - 1]
                        ) {
                            err = ["You didn't say anything!"];
                        }
                        dispatch({
                            type: ADD_NOTIFICATION,
                            payload: {
                                type: "ERROR",
                                message: err[err.length - 1],
                                size: "sm",
                            },
                        });
                        dispatch({
                            type: REMOVE_LAST_ITEM_FROM_CHAT,
                        });
                    }
                })
                .catch((err) => {
                    console.log("Error");
                    console.log(err);
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                    dispatch({
                        type: REMOVE_LAST_ITEM_FROM_CHAT,
                    });
                });
            dispatch({
                type: SET_IS_THINKING,
                isThinking: false,
            });
        };
})();

// reset chat
export const resetChat = () => async (dispatch) => {
    dispatch({
        type: RESET_CHAT,
    });
};

// toggle isRecording
export const toggleIsRecording = (val) => async (dispatch) => {
    dispatch({
        type: SET_RECORDING,
        isRecording: val,
    });
};

// set is gapi ready
export const setIsGapiReady = (isGapiReady) => (dispatch) => {
    dispatch({
        type: SET_IS_GAPI_READY,
        isGapiReady,
    });
};

// stop all onging audio
export const stopAllAudio = () => (dispatch) => {
    dispatch({
        type: STOP_ALL_AUDIO,
    });
};
// add audio
export const addAudio = (audio) => (dispatch) => {
    dispatch({
        type: ADD_AUDIO,
        audio,
    });
};

export const setIsPerformingAction = (isPerformingAction) => (dispatch) => {
    dispatch({
        type: SET_IS_PERFORMING_ACTION,
        isPerformingAction,
    });
};

export const handleSpeakFallback =
    (text, lang, gender, resolve) => async (dispatch) => {
        dispatch(stopAllAudio());
        const gapiVoice = TTSVoices[lang].gapi[gender];
        const textAudio = await getAudioFromTextInLocalStorage(
            `${text}|${gender}`
        );
        if (textAudio) {
            // console.log(`found ${text} in database`);
            dispatch(setReceptionist("TALK"));
            const aud = new Audio(`data:audio/wav;base64, ${textAudio}`);
            aud.loop = false;
            aud.addEventListener("ended", function onEnd() {
                dispatch(setReceptionist("IDLE"));
                dispatch(setIsPerformingAction(false));
                aud.removeEventListener("ended", onEnd);
                resolve();
            });
            aud.play()
                .then((_) => {
                    dispatch(setIsPerformingAction(true));
                    dispatch(stopAllAudio());
                    dispatch(addAudio(aud));
                })
                .catch((err) => {
                    console.log(err);
                    resolve();
                });
        } else {
            window.gapi.client.texttospeech.text
                .synthesize({
                    input: { text: text },
                    voice: {
                        languageCode: lang,
                        name: gapiVoice,
                        ssmlGender: gender.capitalize(),
                    },
                    audioConfig: {
                        audioEncoding: "MP3",
                    },
                })
                .then((res) => {
                    dispatch(setReceptionist("TALK"));
                    const aud = new Audio(
                        `data:audio/wav;base64, ${res.result.audioContent}`
                    );
                    aud.loop = false;
                    aud.addEventListener("ended", function onEnd() {
                        dispatch(setReceptionist("IDLE"));
                        dispatch(setIsPerformingAction(false));
                        aud.removeEventListener("ended", onEnd);
                        resolve();
                    });
                    aud.play()
                        .then((_) => {
                            dispatch(setIsPerformingAction(true));
                            dispatch(stopAllAudio());
                            dispatch(addAudio(aud));
                        })
                        .catch((err) => {
                            console.log(err);
                            resolve();
                        });
                    addAudioToLocalStorage(
                        `${text}|${gender}`,
                        res.result.audioContent
                    );
                })
                .catch((err) => {
                    console.log(err);
                    resolve();
                });
        }
    };

export const handleSpeakThirdParty =
    (text, lang, url, gender, resolve) => async (dispatch) => {
        dispatch(stopAllAudio());
        const textAudio = await getAudioFromTextInLocalStorage(
            `${text}|${gender}`
        );
        if (textAudio) {
            dispatch(setReceptionist("TALK"));
            const aud = new Audio(`data:audio/wav;base64, ${textAudio}`);
            aud.loop = false;
            aud.addEventListener("ended", function onEnd() {
                dispatch(setReceptionist("IDLE"));
                dispatch(setIsPerformingAction(false));
                aud.removeEventListener("ended", onEnd);
                resolve();
            });
            aud.play()
                .then((_) => {
                    dispatch(setIsPerformingAction(true));
                    dispatch(stopAllAudio());
                    dispatch(addAudio(aud));
                })
                .catch((err) => {
                    console.log(err);
                    resolve();
                });
        } else {
            await axios
                .get(
                    `${url}?text=${text}&lang=${lang
                        .split("-")
                        .shift()}&gender=${gender}&base64=1`
                )
                .then(async (res) => {
                    dispatch(setReceptionist("TALK"));
                    const aud = new Audio(
                        `data:audio/wav;base64, ${res.data.audioContent}`
                    );
                    aud.loop = false;
                    aud.addEventListener("ended", function onEnd() {
                        dispatch(setReceptionist("IDLE"));
                        dispatch(setIsPerformingAction(false));
                        aud.removeEventListener("ended", onEnd);
                        resolve();
                    });
                    aud.play()
                        .then((_) => {
                            dispatch(setIsPerformingAction(true));
                            dispatch(stopAllAudio());
                            dispatch(addAudio(aud));
                        })
                        .catch((err) => {
                            console.log(err);
                            resolve();
                        });
                    addAudioToLocalStorage(
                        `${text}|${gender}`,
                        res.data.audioContent
                    );
                })
                .catch((err) => {
                    console.log("Error");
                    console.log(err);
                    resolve();
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                });
        }
    };

export const speakNoChunk = (text, language, isGapiReady) => (dispatch) =>
    new Promise(async (resolve, reject) => {
        if (!text || text.length === 0) {
            resolve();
            return;
        }
        if (isGapiReady) await dispatch(initTTSAPI());
        let thirdPartyAPI = null;
        let canSpeak = false;
        let gender = "female";
        try {
            const receptionistVoice =
                store.getState().settings.applicationSettings.receptionistVoice;
            canSpeak = receptionistVoice.on;
            thirdPartyAPI = receptionistVoice.endpoints;
            gender = receptionistVoice.gender || gender;
            if (thirdPartyAPI !== null)
                thirdPartyAPI = thirdPartyAPI[languageCodes_[language]];
        } catch (e) {}

        const voice = getBrowserVoiceName(language, gender);

        if (!canSpeak || isListening()) {
            resolve();
            return;
        }

        if (thirdPartyAPI !== null && thirdPartyAPI !== undefined) {
            // use 3rd party API
            const _text = Array.isArray(text) ? text.join(" ") : text;
            dispatch(
                handleSpeakThirdParty(
                    _text,
                    language,
                    thirdPartyAPI,
                    gender,
                    resolve
                )
            );
        } else if (voice === undefined) {
            if (!isGapiReady) return resolve();
            // use google API TTS
            const _text = Array.isArray(text) ? text.join(" ") : text;
            dispatch(handleSpeakFallback(_text, language, gender, resolve));
        } else {
            // use default browser TTS
            if (speechSynthesis.speaking) {
                // SpeechSyn is currently speaking, cancel the current utterance(s)
                await speechSynthesis.cancel();
                // Make sure we don't create more than one timeout...
                if (speakTimeout !== null) clearTimeout(speakTimeout);
                speakTimeout = setTimeout(
                    () => dispatch(speakNoChunk(text, language, isGapiReady)),
                    250
                );
            } else {
                if (Array.isArray(text)) {
                    for (let t of text) {
                        const utterance = new SpeechSynthesisUtterance();
                        utterance.lang = language;
                        utterance.voice = voice;
                        dispatch(setReceptionist("TALK"));
                        utterance.text = t;
                        if (t === text[text.length - 1])
                            // last item of array?
                            utterance.onend = () => {
                                dispatch(setReceptionist("IDLE"));
                                dispatch(setIsPerformingAction(false));
                                resolve();
                            };
                        dispatch(setIsPerformingAction(true));
                        await speechSynthesis.speak(utterance);
                    }
                } else {
                    const utterance = new SpeechSynthesisUtterance();
                    utterance.lang = language;
                    utterance.voice = voice;
                    utterance.onend = () => {
                        dispatch(setReceptionist("IDLE"));
                        dispatch(setIsPerformingAction(false));
                        resolve();
                    };
                    utterance.text = text;
                    dispatch(setReceptionist("TALK"));
                    dispatch(setIsPerformingAction(true));
                    await speechSynthesis.speak(utterance);
                }
            }
        }
    });

export const speechSynthesisChunker =
    (message, language, isGapiReady, maxByteLength = 120) =>
    async (dispatch) => {
        if (!message || message.length === 0) return;
        console.log(message);
        if (byteLength(message) > maxByteLength) {
            const sentences = sentenceTokenizer(message, language);
            let splittedMessage = [""];
            let i = 0;
            for (let sentence of sentences) {
                if (
                    byteLength(splittedMessage[i]) < maxByteLength &&
                    byteLength(splittedMessage[i]) + byteLength(sentence) <
                        maxByteLength
                ) {
                    splittedMessage[i] = splittedMessage[i] + " " + sentence;
                } else {
                    if (byteLength(sentence) > maxByteLength) {
                        const chunks = chunkSubstr(sentence, maxByteLength);
                        splittedMessage.push(...chunks);
                        i = i + chunks.length;
                    } else {
                        splittedMessage[i + 1] = sentence;
                        i++;
                    }
                }
            }
            splittedMessage = splittedMessage.filter(Boolean);
            await dispatch(
                speakNoChunk(splittedMessage, language, isGapiReady)
            );
        } else {
            dispatch(speakNoChunk(message, language, isGapiReady));
        }
    };

const byteLength = (str) => {
    // returns the byte length of an utf8 string
    let s = str.length;
    for (let i = str.length - 1; i >= 0; i--) {
        const code = str.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) s++;
        else if (code > 0x7ff && code <= 0xffff) s += 2;
        if (code >= 0xdc00 && code <= 0xdfff) i--; //trail surrogate
    }
    return s;
};

const sentenceTokenizer = (message, language) => {
    switch (language) {
        case languageCodes.japanese:
            const tokenizedMessage = tokenizer_jp.tokenize(message);
            let sentences = [""];
            tokenizedMessage.forEach(({ value, tag }) => {
                const lastSentence = sentences[sentences.length - 1];
                const isTagPunctuation = tag === "punctuation";
                sentences[sentences.length - 1] = isTagPunctuation
                    ? `${lastSentence}${value}`.trim()
                    : `${lastSentence} ${value}`.trim();
                if (isTagPunctuation) sentences.push("");
            });
            return sentences;
        default:
            tokenizer.setEntry(message);
            return tokenizer.getSentences();
    }
};

const chunkSubstr = (str, maxByteLength) => {
    const chunks = str.split(" ");
    const newChunks = [""];
    let position = 0;
    chunks.forEach((chunk) => {
        if (
            byteLength(chunk) + byteLength(newChunks[position]) <
            maxByteLength
        ) {
            newChunks[position] = (newChunks[position] + " " + chunk).trim();
        } else {
            newChunks[position + 1] = chunk;
            position++;
        }
    });
    return newChunks.filter(Boolean);
};

const getDefaultMessageProviderConfig = () => {
    const messageProvider =
        store.getState().settings?.applicationSettings.messageProvider;
    return messageProvider === "slack"
        ? DEFAULT_SLACK_CONFIG
        : DEFAULT_TEAMS_CONFIG;
};

//
const handleMessageResponse =
    (response, modals, history, language, isGapiReady) => (dispatch) => {
        // cho mình gặp anh tuấn anh
        console.log(response);
        const langSplit = language.split("-").shift();
        const { showMeetStaffModal, showFindMeetingModal } = modals;
        const applicationSettings =
            store.getState().settings?.applicationSettings;
        const appearanceSettings =
            store.getState().settings?.appearanceSettings;
        const { isUserRecognized, userInfo } = store.getState().user;
        const messageProvider = applicationSettings.messageProvider || "slack";
        const messageProviderConfig =
            applicationSettings?.[messageProvider]?.config ||
            getDefaultMessageProviderConfig();
        const messageProviderFieldNames =
            MESSAGE_PROVIDER_FIELD_NAMES[messageProvider];
        const {
            meeting: { staffs, message },
        } = messageProviderConfig;
        const voiceResponses =
            applicationSettings?.voiceResponses || defaultVoiceResponses;
        const mapVoiceResponses =
            appearanceSettings?.map?.onFindMapFail ||
            defaultOnFindMapFailResponse;

        // if response contains some actions
        if (Array.isArray(response.display_json)) {
            response.display_json.forEach((action) => {
                switch (action.type) {
                    case "slack_notify":
                        let slackNotifySpeakOnSuccess =
                            voiceResponses?.delivery?.after?.unknown?.[
                                language
                            ];
                        if (isUserRecognized)
                            slackNotifySpeakOnSuccess =
                                voiceResponses?.delivery?.after?.known?.[
                                    language
                                ]?.replace("<name>", userInfo.displayName);
                        dispatch(
                            sendMessageWithProvider({
                                message: action.params.message,
                                staffIdMessageProvider:
                                    action.params.mention.map(
                                        (mention) => mention.slack_id
                                    ),
                                channel:
                                    action.params?.channel ||
                                    messageProviderConfig.delivery[
                                        messageProviderFieldNames.channels
                                    ],
                                speakOnSuccess:
                                    action.params?.response?.[langSplit] ||
                                    slackNotifySpeakOnSuccess,
                                language,
                                isGapiReady,
                                messageProvider,
                            })
                        );
                        return;
                    case "meeting":
                        showMeetStaffModal(
                            action.params?.show_staff_list,
                            action.params?.mention || staffs,
                            action.params?.message || message,
                            action.params?.response_before?.[langSplit] ||
                                voiceResponses.meeting.seeingSomeone.before,
                            action.params?.response_after?.[langSplit] ||
                                voiceResponses.meeting.seeingSomeone.after
                        );
                        return;
                    case "interview":
                        showMeetStaffModal(
                            action.params?.show_staff_list,
                            action.params?.mention || staffs,
                            action.params?.message || message,
                            action.params?.response_before?.[langSplit] ||
                                voiceResponses.interview.before,
                            action.params?.response_after?.[langSplit] ||
                                voiceResponses.interview.after
                        );
                        return;
                    case "schedule":
                        let scheduleSpeakBefore =
                            voiceResponses?.meeting?.meeting?.before?.unknown?.[
                                language
                            ];
                        if (isUserRecognized)
                            scheduleSpeakBefore =
                                voiceResponses?.meeting?.meeting?.before?.known?.[
                                    language
                                ]?.replace("<name>", userInfo.displayName);
                        dispatch(
                            speechSynthesisChunker(
                                action.params?.response?.[langSplit] ||
                                    scheduleSpeakBefore,
                                language,
                                isGapiReady
                            )
                        );
                        showFindMeetingModal();
                        return;
                    case "map":
                        let mapVoiceResponse =
                            mapVoiceResponses?.unknown?.[language];
                        if (isUserRecognized)
                            mapVoiceResponse = mapVoiceResponses?.known?.[
                                language
                            ]?.replace("<name>", userInfo.displayName);
                        history.push("/reception/map", {
                            buttonName: action.params.button_name,
                            voiceResponse:
                                action.params?.response?.[langSplit] ||
                                mapVoiceResponse,
                        });
                        return;
                    default:
                        return null;
                }
            });
        } else {
            // play message
            dispatch(
                speechSynthesisChunker(response.response, language, isGapiReady)
            );
        }
    };

const isListening = () => SpeechRecognition.getRecognitionManager().listening;

const getBrowserVoiceName = (lang, gender) => {
    const browserVoices = window.speechSynthesis.getVoices();
    return browserVoices.find((voice) =>
        TTSVoices[lang].browser[gender].includes(voice.name)
    );
};

// load google tts api
export const loadTextToSpeechApi = () => async (dispatch) => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/client.js";
    script.onload = () => {
        window.gapi.load("client:auth2", async () => {
            await dispatch(initTTSAPI(true));
        });
    };
    document.body.appendChild(script);
};

const initTTSAPI =
    (firstInitialization = false) =>
    (dispatch) =>
        new Promise(async (resolve, reject) => {
            if (isUsingTTSApi) return resolve(true);
            dispatch(setIsGapiReady(false));
            const TTS_DOC = await getDocs("tts");
            const API_KEY_TTS =
                store.getState().settings.applicationSettings.gapi?.textToSpeech
                    ?.key || process.env.REACT_APP_GAPI_TTS_KEY;
            calenderAPIToken = window.gapi.client.getToken();
            window.gapi.client.setToken(null);
            window.gapi.client
                .init({
                    apiKey: API_KEY_TTS,
                    discoveryDocs: TTS_DOC,
                })
                .then(
                    function () {
                        if (firstInitialization) {
                            window.gapi.client.load(
                                "texttospeech",
                                "v1",
                                () => {
                                    isUsingTTSApi = true;
                                    dispatch(setIsGapiReady(true));
                                    resolve(true);
                                }
                            );
                        } else {
                            isUsingTTSApi = true;
                            dispatch(setIsGapiReady(true));
                            resolve(true);
                        }
                    },
                    function (err) {
                        console.log(err);
                        resolve(false);
                    }
                );
        });

export const initCalendarAPI = () => (dispatch) =>
    new Promise(async (resolve, reject) => {
        if (!isUsingTTSApi) return resolve(true);
        dispatch(setIsGapiReady(false));

        const CALENDAR_DOC = await getDocs("calendar");
        const API_KEY_CALENDAR =
            store.getState().settings.applicationSettings.gapi?.calendar?.key ||
            process.env.REACT_APP_GAPI_CALENDAR_KEY;
        const CLIENT_ID =
            store.getState().settings.applicationSettings.gapi?.calendar
                ?.clientId || process.env.REACT_APP_GAPI_CALENDAR_CLIENT_ID;

        window.gapi.client
            .init({
                apiKey: API_KEY_CALENDAR,
                discoveryDocs: CALENDAR_DOC,
                clientId: CLIENT_ID,
                scope: "https://www.googleapis.com/auth/calendar",
            })
            .then(
                function () {
                    resolve(true);
                    isUsingTTSApi = false;
                    dispatch(setIsGapiReady(true));
                    if (calenderAPIToken) {
                        window.gapi.client.setToken(calenderAPIToken);
                        calenderAPIToken = null;
                    }
                },
                function (err) {
                    console.log(err);
                    resolve(false);
                }
            );
    });

export const stopAllActions = () => (dispatch) => {
    dispatch(stopAllAudio());
    SpeechRecognition.stopListening();
    speechSynthesis.cancel();
    dispatch(setReceptionist("IDLE"));
    dispatch(setIsPerformingAction(false));
};

export const scrollToBottom = () => {
    try {
        const chat = document.getElementById("chatContent");
        chat.scrollTop = chat.scrollHeight;
    } catch (e) {}
};

export const decodeHTMLEntities = (text) => {
    const entities = [
        ["amp", "&"],
        ["apos", "'"],
        ["#x27", "'"],
        ["#x2F", "/"],
        ["#39", "'"],
        ["#47", "/"],
        ["lt", "<"],
        ["gt", ">"],
        ["nbsp", " "],
        ["quot", '"'],
    ];
    for (let i = 0, max = entities.length; i < max; ++i)
        text = text.replace(
            new RegExp("&" + entities[i][0] + ";", "g"),
            entities[i][1]
        );

    return text;
};

export const setOnScreenKeyboard = (isOnScreenKeyboard) => (dispatch) => {
    dispatch({
        type: SET_IS_ON_SCREEN_KEYBOARD,
        isOnScreenKeyboard,
    });
};

export const addToMessages = (message, isReceiver) => (dispatch) => {
    dispatch({
        type: ADD_TO_CHAT,
        message,
        isReceiver,
    });
};

const getDocs = (type) =>
    new Promise(async (resolve, reject) => {
        switch (type) {
            case "tts":
                axios
                    .get(
                        "https://texttospeech.googleapis.com/$discovery/rest?version=v1"
                    )
                    .then((res) => {
                        resolve([res.data]);
                    })
                    .catch((err) => {
                        console.log("Error");
                        console.log(err.message);
                        resolve([]);
                    });
                return;
            case "calendar":
                axios
                    .get(
                        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
                    )
                    .then((res) => {
                        resolve([res.data]);
                    })
                    .catch((err) => {
                        console.log("Error");
                        console.log(err.message);
                        resolve([]);
                    });
                return;
            default:
                resolve([]);
                return;
        }
    });

const getMessageResponse = (res, language) => {
    const langSplit = language.split("-").shift();
    if (!!res.response && res.response.length > 0) return res.response;
    else if (res.display_json && res.display_json.length > 0) {
        const applicationSettings =
            store.getState().settings?.applicationSettings;
        const voiceResponses =
            applicationSettings?.voiceResponses || defaultVoiceResponses;
        const { isUserRecognized, userInfo } = store.getState().user;
        switch (res.display_json[0].type) {
            case "slack_notify":
                let slackNotifyVoiceResponse =
                    voiceResponses?.delivery?.after?.unknown?.[language];
                if (isUserRecognized)
                    slackNotifyVoiceResponse =
                        voiceResponses?.delivery?.after?.known?.[
                            language
                        ]?.replace("<name>", userInfo.displayName);
                return (
                    res.display_json[0]?.params?.response?.[langSplit] ||
                    slackNotifyVoiceResponse
                );
            case "meeting":
                let meetingVoiceResponse =
                    voiceResponses?.meeting?.seeingSomeone?.before?.unknown?.[
                        language
                    ];
                if (isUserRecognized)
                    meetingVoiceResponse =
                        voiceResponses?.meeting?.seeingSomeone?.before?.known?.[
                            language
                        ]?.replace("<name>", userInfo.displayName);
                return (
                    res.display_json[0]?.params?.response_before?.[langSplit] ||
                    meetingVoiceResponse
                );
            case "interview":
                let interviewVoiceResponse =
                    voiceResponses?.interview?.before?.unknown?.[language];
                if (isUserRecognized)
                    interviewVoiceResponse =
                        voiceResponses?.interview?.before?.known?.[
                            language
                        ]?.replace("<name>", userInfo.displayName);
                return (
                    res.display_json[0]?.params?.response_before?.[langSplit] ||
                    interviewVoiceResponse
                );
            default:
                return "";
        }
    } else return "";
};

/* Gets bot Id from current url, updates redux with id, return first bot lists of bots  if is not provided*/
export const updateCurrentBotIdFromURL = () => async (dispatch) => {
    let botIdParam = null;
    try {
        botIdParam = parseInt(
            new URLSearchParams(window.location.search).get("bot_id") ||
                localStorage.getItem("botId")
        );
        const isBotIdInBotList = bots.find((bot) => bot.bot_id === botIdParam);
        if (!isBotIdInBotList) {
            botIdParam = null;
        } else localStorage.setItem("botId", botIdParam.toString());
    } catch (_) {}
    await dispatch({
        type: SET_BOT_ID,
        botId: botIdParam || parseInt(bots[0].bot_id),
    });
};

export const getCurrentBotId = () => store.getState().chat.botId;

export const setCurrentBotId = (botId) => async (dispatch) => {
    await dispatch({
        type: SET_BOT_ID,
        botId: parseInt(botId),
    });
};

export const areArraysSame = (array1, array2) =>
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index]);

export const areObjectsSame = (x, y) => {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y;
    return x &&
        y &&
        tx === "object" &&
        tx === ty &&
        x.constructor === y.constructor
        ? ok(x).length === ok(y).length &&
              ok(x).every((key) => areObjectsSame(x[key], y[key]))
        : x === y;
};

export const isVariableValid = (variable) =>
    variable !== undefined && variable !== null;
