import moment from "moment-timezone";
import { initCalendarAPI } from "./chatActions";
import { addToNotifications } from "./notificationActions";
import { translate } from "../../utils/i18n";
import { ADD_NOTIFICATION } from "./types";
import CryptoJS from "crypto-js";

export const listenForSignInChange =
    (updateSignInStatus) => async (dispatch) => {
        await dispatch(initCalendarAPI());
        window.gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(updateSignInStatus);
        const isSignedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
        if (isSignedIn) updateSignInStatus(isSignedIn);
        else window.gapi.auth2.getAuthInstance().signIn();
    };

export const getEvents = (timeMin, timeMax) => (dispatch) =>
    new Promise(async (resolve, reject) => {
        if (
            !timeMin ||
            !timeMax ||
            isNaN(new Date(timeMin)) ||
            isNaN(new Date(timeMax))
        ) {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate(
                        "Something went wrong. Check time range!"
                    ),
                    size: "sm",
                },
            });
            resolve(null);
        } else {
            window.gapi.client.calendar.events
                .list({
                    calendarId: "primary",
                    timeZone: moment.tz.guess(),
                    singleEvents: true,
                    timeMin: timeMin.toISOString(),
                    timeMax: timeMax.toISOString(),
                    maxResults: 100,
                    orderBy: "startTime",
                })
                .then((res) => {
                    resolve(res.result);
                })
                .catch((res) => {
                    console.log(res);
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "ERROR",
                            message: translate("Something went wrong!"),
                            size: "sm",
                        },
                    });
                    resolve(null);
                });
        }
    });

export const addEvent = (params, event) => (dispatch) =>
    new Promise(async (resolve, reject) => {
        window.gapi.client.calendar.events
            .insert({
                calendarId: "primary",
                resource: event,
                ...params,
            })
            .then((res) => {
                resolve(res.result);
            })
            .catch((err) => {
                console.log(err);
                dispatch(
                    addToNotifications({
                        type: "ERROR",
                        message: translate(
                            "Something went wrong. Check time range!"
                        ),
                        size: "md",
                    })
                );
                resolve(false);
            });
    });

export const updateEvent = (params, event) => (dispatch) =>
    new Promise(async (resolve, reject) => {
        window.gapi.client.calendar.events
            .update({
                calendarId: "primary",
                resource: event,
                ...params,
            })
            .then((res) => {
                resolve(true);
            })
            .catch((err) => {
                console.log(err);
                dispatch(
                    addToNotifications({
                        type: "ERROR",
                        message: translate(
                            "Something went wrong. Check time range!"
                        ),
                        size: "md",
                    })
                );
                resolve(false);
            });
    });

export const deleteEvent = (eventId) => (dispatch) =>
    new Promise(async (resolve, reject) => {
        window.gapi.client.calendar.events
            .delete({
                calendarId: "primary",
                eventId,
            })
            .then((res) => {
                resolve(true);
            })
            .catch((err) => {
                console.log(err);
                dispatch(
                    addToNotifications({
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "md",
                    })
                );
                resolve(false);
            });
    });

export const decodeDescription = (description) => {
    const SECRET_KEY = process.env.REACT_APP_AES_SECRET_KEY;
    const [_description, additionalInfo] = description?.split(
        "<br/><br/><br/>||"
    ) || [null, null];

    let _return = { description: _description, additionalInfo: {} };
    if (additionalInfo) {
        try {
            const bytes = CryptoJS.AES.decrypt(additionalInfo, SECRET_KEY);
            const decodedAdditionalInfo = JSON.parse(
                bytes.toString(CryptoJS.enc.Utf8)
            );
            _return = { ..._return, additionalInfo: decodedAdditionalInfo };
        } catch (err) {
            console.log(err);
        }
    }
    return _return;
};
